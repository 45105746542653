var DS = window.DS || {};
DS.threeColumn = function (el) {
  var els = {};



  var init = function () {
    els.component = $(el);
    els.columns = els.component.find('.column-single');
    els.columnItems = els.component.find('.column-item');

    if (window.innerWidth > 768) {
      alignColumnItemHeight();
    }
    listen();
  };

  var listen = function () {
    window.addEventListener('resize', function () {
      if (window.innerWidth > 768) {
        alignColumnItemHeight();
      }
    });
  };

  var alignColumnItemHeight = function () {
    //find longest column so we know how many rows we need to check
    var longestColumn = els.columns[0];

    for (var i = 0; i < els.columns.length; i++) {
      if (i+1 == els.columns.length) {
        if (els.columns[i].childElementCount < els.columns[0].childElementCount) {
          longestColumn = els.columns[0];
        }
      }else{
        if (els.columns[i].childElementCount < els.columns[i+1].childElementCount) {
          longestColumn = els.columns[i+1];
        }
      }
    }

    var longestColumnLength = $(longestColumn)[0].childElementCount;


    for (var j = 0; j < longestColumnLength; j++) {

      var $colItem0 = $($(els.columns[0]).find('.column-item')[j]);
      var $colItem1 = $($(els.columns[1]).find('.column-item')[j]);
      var $colItem2 = $($(els.columns[2]).find('.column-item')[j]);

      var heights = [];

      //remove previous style so heights are accurate
      $colItem0.removeAttr('style');
      $colItem1.removeAttr('style');
      $colItem2.removeAttr('style');


      //only check heights that exist
      if (typeof $colItem0.outerHeight() != 'undefined'){
        heights.push($colItem0.outerHeight());
      }
      if (typeof $colItem1.outerHeight() != 'undefined'){
        heights.push($colItem1.outerHeight());
      }
      if (typeof $colItem2.outerHeight() != 'undefined'){
        heights.push($colItem2.outerHeight());
      }

      var largestHeight = Math.max.apply(null, heights);


      //set heights
      $colItem0.outerHeight(largestHeight);
      $colItem1.outerHeight(largestHeight);
      $colItem2.outerHeight(largestHeight);

    }
  };

  init();
};

$(function () {
  window.addEventListener('load', function () {
    var $components = $('.three-column-component');
    if (!$components.length) return;
    $components.each(function (i, el) {
      new DS.threeColumn(el);
    });
  });
});
